<template>
<div class="section">
  <div class="columns is-centered" :class="{ 'px-0 pt-0': isMobile, 'px-6 py-5': isDesktop }">
    <div class="column is-12">
      <Markdown :text="text" />
    </div>
  </div>
</div>
</template>

<script>
import Markdown from '@/components/common/Markdown.vue'
import VueBreakpointMixin from 'vue-breakpoint-mixin'

export default {
  name: 'About',
  components: {
    Markdown,
  },
  mixins: [VueBreakpointMixin],
  mounted () {
    const tds = document.querySelectorAll('td')
    tds.forEach(td => {
      td.classList = 'is-vcentered'
    })
    document.querySelector('table').classList = 'table is-narrow is-fullwidth'
  },
  data() {
    return {
      text: `
## CryptoDesk.bg е вашият удобен портал към света на крипто 

### Кои сме ние?

##### _Първата Регулирана Крипто Борса в България_
##### _Първата Фирма с покритие на битомати в страната_
##### _Първата крипто фирма с частна образователна програма към InvestPro_
##### _Първата магистърска програма по Блокчейн в държавен университет_
&nbsp;

Фирмата **Блокчейн Тех ЕООД** е създадена през 2018 г. с ЕИК 204944198 с регистриран и внесен капитал от 100 000 лв. Дружеството е лицензирано от НАП с номер ВВ-1 достъпен ([тук](./bb1.pdf)) от 16.09.2020 г. за фирми предоставящи услуги за обмен на виртуални валути. 

Блокчейн Тех ЕООД оперира под бранда CryptoDesk.bg, с което име е и известно на българския криптопазар и българската блокчейн/крипто екосистема. 

#### Оперативна дейност

От 2018 г. фирмата инвестира в развитието на българския крипто пазар, като предлага на хората лесен достъп до криптовалути посредством различни услуги. Най-напред, CryptoDesk стартира с физически машини тип ATM, разположени в четирите най-големи български града – София, Пловдив, Варна и Бургас. 

Освен това фирмата обслужва клиенти, осъществявайки OTC (over-the-counter) сделки с индивидуален подход с физически и юридически лица.

Дружеството разполага и с платформата CryptoDesk.bg, която доставя друга удобна възможност на клиентите да оперират с криптовалути, както чрез уеб (браузър), така и чрез мобилното CryptoDesk приложение, като потребителите също така притежават личен профил, съдържащ тяхната поръчкова история. Финално, но не на последно място, CryptoDesk предоставя възможност и за закупуване и продаване на крипто чрез кеш. Това се случва посредством фирми партньори. 

Друг метод за обслужване на клиенти е т.нар. Биткойн касиер – чрез фирма партньор CryptoDesk предлага покупка и продажба на криптоактиви с кешови разплащание, които се случват на две локации – София и Велико Търново.

Блокчейн Тех ЕООД търси постоянно нови ниши и насоки за развитие на CryptoDesk, които да предложи и чрез които да подобри своята екосистема от услуги, като съвсем отскоро брандът предлага и електронен [магазин](https://store.cryptodesk.bg), в който могат да бъдат закупени различни крипто хардуер пособи, като той е само една част от портфолиото на фирмата, което в близко бъдеще предстои да се разраства и с нови продукти.

#### Кратка история

Блокчейн Тех ЕООД е създадена през 2018 г. неслучайно. След големия ръст на Биткойн през 2017 г., достигнал рекордни нива за онова време, след значителното медийно покритие по тази тема и най-големият фактор – любопитство и виждане на перспектива в дигиталните активи, Управителят на фирмата навлиза в средата и задълбочава своите познания в областта, което води и до основаването на Блокчейн Тех. Оттогава насам чрез бранда CryptoDesk.bg, фирмата се старае да обогати българската крипто екосистема чрез своите услуги, чрез тяхното оптимизиране и чрез добавянето на нови такива, като например BTCPay Server и LnPos, с цел развитие на крипто бизнеса в страната, легитимирането му пред обществото, създаване на удобни методи за крипто трансакции и вяра в развитието на криптовалутите и блокчейн в глобален план – техният принос, тяхната идея и тяхното бъдеще. 

#### За екипа

CryptoDesk.bg е съставен от крипто ентусиасти, които са винаги готови да съдействат и да помогнат на потребността на хората/клиентите да извършат желаната от тях операция. Екипът е с около 20 годишен общ опит от сферата на технологиите и финансите, и вече над 5 години от крипто и блокчейн бранша, като поддържа най-добрите световни стандарти и практики. 

#### Медийно и друго присъствие
|                                                   |                                                                                                                                                                                                                                                                                                                                                                        |
|---------------------------------------------------|------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| ![Bloomberg TV](./partners/bloomberg260x128.png)  | [Линк 1](https://www.bloombergtv.bg/a/20-klub-investor/101153-kakvo-predveshtava-dalgata-korelatsiya-na-bitkoyna), [Линк 2](https://www.bloombergtv.bg/a/19-svetat-e-biznes/89701-ekshanat-e-pri-eterium-dokato-horata-sporyat-za-bitkoyn), [Линк 3](https://www.bloombergtv.bg/a/16-biznes-start/38069-zashto-kriptovalutata-na-facebook-pritesnyava-tramp-i-bankite) |
| ![DevStyler](./partners/devstyler260x128.png)     | [Линк 1](https://www.facebook.com/watch/?v=3529600700496330), [Линк 2](https://devstyler.bg/blog/2020/06/24/blockchain-az-vqrvam-v-tova-badeshte-chast-1/), [Линк 3](https://devstyler.bg/blog/2020/06/26/kriptovalutite-klyuchat-kam-finansovoto-priobshtavane-chast-2/)                                                                                               |
| ![Sofia Crypto Meetup](./partners/scm260x128.png) | [Линк 1](https://www.youtube.com/watch?v=WyedSKBJwdI&t=755s), [Линк 2](https://www.youtube.com/watch?v=rsVlkHJun2c&t=835s)                                                                                                                                                                                                                                             |
| ![Nova News](./partners/novanews260x128.png)      | [Линк 1](https://www.vbox7.com/play:545498fd6a)                                                                                                                                                                                                                                                                                                                        |
| ![БНР](./partners/bnr260x128.png)                 | [Линк 1](https://bnr.bg/post/101460070/kriptovalutite-badeshteto-za-koeto-trabva-da-se-podgotvim)                                                                                                                                                                                                                                                                      |
| ![InvestPro](./partners/investpro260x128.png)     | [Линк 1](https://investpro.bg/obuchenia-investpro/investicii-v-kriptovaluti)                                                                                                                                                                                                                                                                                           |
| ![ВУЗФ](./partners/vuzf260x128.png)               | [Линк 1](https://vuzf.bg/bdt)                                                                                                                                                                                                                                                                                                                                          |                                                                                                                                                                                                                                                                                                                               |
      `,
    }
  },
}
</script>
<!-- :src="require(`@/assets/images/crypto/${this.ticker.toLowerCase()}.svg`)" -->

<style lang="scss" scoped>
</style>
